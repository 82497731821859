@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(./Assets/fonts/OpenSans/OpenSans-Regular.ttf) format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url(./Assets/fonts/Montserrat/Montserrat-Medium.ttf) format('opentype');
}

html {
    font-family: 'OpenSans';
}

@media only screen and (min-width: 1025px) {
    .grid-container{
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .description{
        color:#45474B;
        font-weight: 400;
        font-size:36px;
        margin:0 0 30px 0;
    }
}

@media only screen and (max-width: 1024px) {
    .grid-container{
        display: grid;
        grid-template-columns:100%;
    }

    .description{
        color:#45474B;
        font-weight: 400;
        font-size:25px;
        margin:0;
    }
}

.title{
    color:#349DD3;
    font-size:16px;
    font-weight: 450;
    margin-bottom:0;
    margin-top: 0;
}

.paragraph{
    color:#303030;
    font-size:12px;
    text-align:left;
    font-family: 'Montserrat';
    line-height:20px;
}

.gradient-button{
    background-image: linear-gradient(to right,#799DCB , #A26199);
    border:none;
    color:#fff;
    font-weight: 600;
    font-size:12px;
}

.white-button{
    background-color: #ffffff;
    border:none;
    color:#000000;
    font-weight: 600;
    font-size:12px;
}

a:-webkit-any-link {
    text-decoration: none;
}