.founder{
    background: url('../../Assets/images/founder.png');
    background-position: center;
    background-size: cover;
}

.project{
    background-color: #6193BF;
}

@media only screen and (min-width: 1025px) {
    .about{
        padding: 10px 50px;
    }

    .about-container{
        padding: 30px 0px;
        width: 80%;
        margin:auto;
    }
    
    .about-grid-item{
        margin: 40px 20px;
    }
    
    .about-image{
        background: url('../../Assets/images/about.png');
        background-position: center;
        background-size: cover;
        min-height:250px;
    }
    
    .project-grid-container{
        display: grid;
        grid-template-columns: 65% 35%;
    }
}

@media only screen and (max-width: 1024px) {
    .about{
        padding: 10px 25px;
    }
    
    .about-grid-item{
        margin: 20px 0;
    }
    
    .about-image{
        background: url('../../Assets/images/about.png');
        background-position: center;
        background-size: cover;
        width:100%;
        height:300px;
    }
    
    .project-grid-container{
        display: grid;
        grid-template-columns: 100%;
        background-color: #6193BF;
    }

    .founder-item{
        background-color:rgba(255,255,255,0.5);
        padding: 10px;
    }

    .talk-button{
        margin-top:5px;
    }
}
