@media only screen and (min-width: 1025px) {
    .faqs{
        padding: 10px 50px;
        background-color:#F7FAFD;
    }

    .faqs-heading{
        margin: 50px auto;
        text-align: center;
        width: 30%;
    }

    .qa-container{
        width: 50%;
        margin: 10px auto;
        padding: 20px 0;
    }

    .qa-section{
        border-bottom: 2px solid #E7E7E8;
        padding: 20px 0;
    }

    .question-section{
        display:grid;
        grid-template-columns: 80% 20%;
        position:relative;
    }

    .faqs-container{
        margin: 0 auto;
        text-align: center;
        padding: 40px;
    }

    .faqs-form{
        width:50%;
        margin:0 auto;
        text-align: left;
    }

    .faqs-heading-text{
        text-align:center;
        margin: 0 20px 50px 0;
    }
}

@media only screen and (max-width: 1024px) {
    .faqs{
        padding: 10px 25px;
    }

    .faqs-heading{
        margin: 30px auto;
        text-align: center;
        width: 90%;
    }

    .qa-container{
        width: 90%;
        margin: 0 auto;
        padding-bottom: 0 0 30px 0;
    }

    .qa-section{
        border-bottom: 2px solid #E7E7E8;
        padding: 20px 0;
    }

    .question-section{
        display:grid;
        grid-template-columns: 80% 20%;
        position:relative;
    }

    .faqs-container{
        margin: 0 auto;
        text-align: center;
        padding: 30px 25px;
        background-color:#F7FAFD;
    }

    .faqs-form{
        text-align: left;
    }

    .faqs-heading-text{
        text-align:center;
        margin: 0 0 50px 0;
    }
}

.qa-description{
    font-size: 20px;
    margin-bottom: 0;
}

.qa-paragraph{
    color:#8F9194;
}

.qa-button{
    background-image: linear-gradient(#799DCB, #A26199);
    border:none;
    border-radius: 50%;
    padding: 2px;
    width:30px;
    height:30px;
    float:right;
    position: absolute;
    right: 0;
    cursor: pointer;
}

.qa-icon{
    width:20px;
    height:20px;
    color:#ffffff;
    margin: auto;
}

.form-input{
    width:90%;
    border:none;
    padding: 15px;
    margin: 10px 0;
}

.faq-send-button{
    padding: 20px 50px;
}

