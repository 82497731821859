@media only screen and (min-width: 1025px) {
    .footer{
        padding:50px 0;
        width:80%;
        margin:0 auto;
        display: grid;
        grid-template-columns: 40% 20% 20% 20%;
    }

    .footer-grid-container{
        display: grid;
        grid-template-columns: 20% 70%;
    }
}

@media only screen and (max-width: 1024px) {
    .footer{
        padding:10px 15px;
        display: grid;
        grid-template-columns: 100%;
    }

    .footer-grid-container{
        display: grid;
        grid-template-columns: 100%;
    }
    
    .footer-grid-item{
        margin-top:20px;
    }
}

.footer-logo{
    height: 60px;
    width: 60px;
    margin:5px 0;
}

.footer-paragraph{
    color:#45474B;
    font-family: 'Montserrat';
    text-align:left;
    margin:0;
}

.sub-footer{
    background-image: linear-gradient(to right,#799DCB , #A26199);
    text-align: center;
    font-size:12px;
    font-family: 'Montserrat';
    padding:10px;
}