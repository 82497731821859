.blue{
    background-color:#F7FAFD;
}

.contact-description{
    text-align: center;
    margin-bottom:0;
}

.contact-paragraph{
    text-align: center;
    margin-top:0;
}

.contact-logo{
    width:40px;
    height:40px;
    color:#73A5D2;
}

@media only screen and (min-width: 1025px) {   
    .contacts{
       padding: 10px 50px;
    }

    .contacts-grid-container{
        display: grid;
        grid-template-columns: 33.33% 33.33% 33.33%;
    }

    .contacts-heading{
        margin: 30px auto;
        text-align: center;
        width: 60%;
    }

    .contacts-container{
        padding: 30px 0px;
        width: 70%;
        margin:auto;
        text-align: center;
    }

    .contacts-heading-text{
        text-align:center;
        margin: 0 0 50px 0;
    }

    .contacts-form{
        width:70%;
        margin:0 auto;
        text-align: center;
    }

    .faq-form-input{
        border:none;
        padding: 15px;
        margin: 10px 0;
        width: 95%;
    }

    .send-button{
        padding: 20px 0;
        width: 100%;
    }
}

@media only screen and (max-width: 1024px) {  
    .contacts-grid-container{
        display: grid;
        grid-template-columns: 100%;
    }

    .contacts{
        padding: 10px 25px;
    }

    .contacts-heading{
        margin: 10px auto;
        text-align: center;
    }

    .contacts-container{
        margin: 0 auto;
        text-align: center;
        padding: 10px 0;
        background-color:#F7FAFD;
    }

    .contacts-heading-text{
        text-align:center;
        margin: 0 0 50px 0;
    }

    .contacts-form{
        text-align: center;
    }

    .faq-form-input{
        border:none;
        padding: 15px;
        margin: 10px auto;
        width: 90%;
    }

    .send-button{
        padding: 20px 0;
        width: 100%;
    }
}




