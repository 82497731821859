.testimonial-paragraph{
    text-align:center;
}

.quote{
    font-size: 40px;
    color:#DDDDDD;
}

.control-arrow{
    height:25px;
    width:25px;
    background-image: linear-gradient(#799DCB, #A26199) !important;
    border-radius: 50%;
    font-size:8px !important;
    margin-top: 50px !important;
}

.control-prev{
    padding: 0 3px 0 0 !important;
}



@media only screen and (min-width: 1025px) {        
    .services{
        padding: 10px 50px;
    }

    .services-container{
        padding: 30px 0px;
        width: 90%;
        margin:auto;
    }

    .testimonials{
        width:35%;
        margin:auto;
        padding: 80px;
    }

    .services-heading{
        margin: 40px auto 10px auto;
        text-align: center;
        width: 30%;
    }

    .services-heading-text{
        text-align: center;
    }

    .services-grid-item{
        margin: 20px;
    }

    .services-image{
        background: url('../../Assets/images/service-1.png');
        background-position: center;
        background-size: cover;
    }

    .services-image-2{
        background: url('../../Assets/images/service-2.png');
        background-position: center;
        background-size: cover;
    }

    .milky{
        background-color: #F8F8F8;
    }

    .solution-section{
        padding-bottom: 50px;
        background-color: #3C3B7B;
    }

    .solution-header{
        text-align:center;
        margin:0 auto;
        padding: 20px 0;
        width: 50%;
    }

    .solution-grid-item{
        padding: 0 20px;
    }

    .solution-text{
        color:#ffffff;
        text-align: center;
    }

    .call{
        margin-right: 5px;
    }

    .arrow-right{
        margin-left: 5px;
    }

    .solution-button{
        padding: 15px 60px;
        font-family: "Montserrat";
        font-weight: normal;
    }

    .right{
        text-align: right;
    }

    .left{
        text-align: left;
    }

    .solution-contact{
        color:white;
        font-family: "Montserrat";
        font-size: 12px;
        text-decoration: underline;
    }
}

@media only screen and (max-width: 1024px) {   
    .services{
        padding: 10px 25px;
    }

    .services-heading{
        margin: 10px auto;
        text-align: center;
    }

    .services-heading-text{
        text-align: center;
    }

    .services-grid-item{
        margin: 20px 0 0 0;
    }

    .services-image{
        background: url('../../Assets/images/service-1.png');
        background-position: center;
        background-size: cover;
        width:100%;
        height:300px;
    }

    .services-image-2{
        background: url('../../Assets/images/service-2.png');
        background-position: center;
        background-size: cover;
        width:100%;
        height:300px;
    }

    .milky{
        background-color: #F8F8F8;
    }

    .testimonial-section{
        padding:20px;
        margin:50px 0;
    }

    .solution-section{
        padding: 30px 25px;
        background-color: #3C3B7B;
    }

    .solution-header{
        text-align:center;
        margin:0 auto;
        padding: 10px 0;
    }

    .solution-grid-item{
        padding: 0;
        text-align: center;
        margin-top:20px;
    }

    .solution-text{
        color:#ffffff;
        text-align: center;
    }

    .call{
        margin-right: 5px;
    }

    .arrow-right{
        margin-left: 5px;
    }

    .solution-button{
        padding: 15px 60px;
        font-family: "Montserrat";
        font-weight: normal;
    }

    .solution-contact{
        color:white;
        font-family: "Montserrat";
        font-size: 12px;
        text-decoration: underline;
    }
}
