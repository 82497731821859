.talk-grid-container{
    display: grid;
    grid-template-columns: 50% 50%;
}

.talk-button{
    margin-top:30px;
    padding:15px 40px;
}

.business-banner{
    background: url('../../Assets/images/business-image.png');
    background-position: center;
    background-size: cover;
}

.lightblue-background{
    background-color:#F7FAFD;
}

.banner{
    background: url('../../Assets/images/blue-banner.png');
    background-position: center;
    background-size: cover;
    text-align: center;
}

.offer-logo{
    height: 80px;
    width: 80px;
}

.white-text{
    color:#fff;
}

@media only screen and (max-width: 1024px) {
    .welcome{
        padding: 10px 25px;
    }

    .welcome-grid-container{
        display: grid;
        grid-template-columns: 0% 100%;
    }

    .welcome-banner{
        background: url('../../Assets/images/home-banner.png');
        background-size: cover;
    }

    .welcome-content{
        padding:40px;
    }
     
    .welcome-grid-item{
        height: 250px;
        background-color:rgba(0,0,0,0.4);
    }

    .welcome-description{
        color:#eeeeee;
    }

    .talk-content{
        margin:20px 0;
    }
    
    .talk-logo{
        height: 50px;
        width: 50px;
    }
        
    .talk{
        padding: 25px;
    }

    .content-grid-item{
        margin-top:20px;
        padding:10px;
    }

    .banner-text{
        font-size: 20px;
        color:#fff;
        margin:0;
        padding:20px 40px;
    }

    .offer{
        text-align: center;
        padding: 0px 40px;
        margin:40px 0;
    }

    .offer-grid-container{
        display: grid;
        grid-template-columns: 100%;
    }   
    
    .offer-grid-item{
        padding:5px 0;
        text-align:center;
    }
    
    .offer-description{
        color:#45474B;
        font-weight: 400;
        text-align: center;
        font-size:20px;
        margin:0;
    }

    .offer-paragraph{
        color:#45474B;
        text-align:center;
        margin: 10px 0 0 0;
    }

    .schedule-banner{
        background: url('../../Assets/images/purple-banner.png');
        background-position: center;
        background-size: cover;
        padding:30px 40px;
    }

    .schedule-button-container{
        display: flex;
        align-items: center;
        justify-content: left;
        margin-top:30px;
    }

    .offer-text{
        font-size: 20px;
        color:#fff;
        margin-top:10px;
    }
}

@media only screen and (min-width: 1025px) {
    .welcome-grid-container{
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .welcome-banner{
        background: url('../../Assets/images/home-banner.png');
        background-size: cover;
        background-position: center;
    }

    .welcome-container{
        width: 80%;
        margin:auto;
    }

    .welcome-content{
        margin:40px;
        width:70%;
    }

    .welcome-grid-item{
        min-height: 300px;
    }

    .talk-content{
        margin:100px 0;
    }

    .shape-grid-item{
        margin:0 50px;
    }
    
    .talk-button{
        margin-top:30px;
        padding:15px 40px;
    }
    
    .talk-logo{
        height: 70px;
        width: 70px;
    }
    
    .content-grid-item{
        padding:15px 10px;
    }

    .banner-text{
        font-size: 32px;
        color:#fff;
        padding:50px 0;
        width: 50%;
        margin:0 auto;
    }
    
    .offer{
        text-align: center;
        padding: 0;
        margin:80px auto;
    }

    .offer-grid-container{
        display: grid;
        grid-template-columns: 30% 70%;
    }

    .offer-grid-item{
        padding:15px 5px;
    }

    .offer-description{
        color:#45474B;
        font-weight: 400;
        text-align: left;
        font-size:20px;
        margin:0;
    }

    .offer-paragraph{
        color:#45474B;
        text-align:left;
        margin: 10px 0 0 0;
    }
    
    .schedule-banner{
        background: url('../../Assets/images/purple-banner.png');
        background-position: center;
        background-size: cover;
        padding:50px 0;
    }

    .schedule-button-container{
        display: flex;
        align-items: center;
        justify-content: right;
    }
}

.schedule-banner-content{
    text-align:left;  
}

.schedule-button{
    padding:15px 40px;
}

