.App-header{
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}

.logo-grid-item{
    text-align: center;
}

.nav-grid-container{
    text-align: left;
    display: grid;
    grid-template-columns: 12% 12% 12% 12% 30% 8%;
}

.nav-item{
    color:#3C3B7B;
    font-weight: normal;
    font-size: 10px;
}

.active{
    font-weight: bold;
}

.menu-logo{
    width:120px;
    text-align: center;
    margin:10px auto;
}

.language-button{
    border:none;
    background-color: transparent;
    padding: 0;
}

.language-globe{
    width:30px;
    height:30px;
    color:#3C3B7B;
}

.nav-button{
    padding:10px 30px;
    margin:15px 0;
}

@media only screen and (min-width: 1025px) {
    .header-grid-container{
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .hamburger-container{
        display:none;
    }
}

@media only screen and (max-width: 1024px) {
    .header-grid-container{
        display: grid;
        grid-template-columns:35% 65%;
    }

    .nav-grid-container{
        display: none;
    }

    .logo-grid-item{
        text-align: left;
    }

    .hamburger-container{
        padding:10px;
    }

    .hamburger-button{
        border: none;
        background: none;
        padding:5px;
        margin: auto;
    }

    .hamburger-logo{
        width:30px;
        height:30px;
    }
}